import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Alert, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Skeleton, Typography } from '@mui/material';
import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import MainTheme from './Wrapper/MainTheme';
import PlanningForm from './PlanningForm';

function tomorrow() {
	const today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);
	return tomorrow;
}

function nextDay(day) {
	let nextDay = new Date(day);
	nextDay.setDate(day.getDate() + 1);
	return nextDay;
}

class ScheduleForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chosenTime: props.state.chosenTime,
			day: props.state.day,
			weeks_loaded: false,
			capacities_loaded: false,
			week_index: props.state.week_index,
			week_chosen: false,
			clickedOnWeekIndex: props.state.clickedOnWeekIndex,
			weeks: [],
			weeks_new: [],
		};
	}

	componentDidMount() {
		//! reference point
		const apiCallURL = process.env.REACT_APP_API_URL + '/weeks/8';
		fetch(apiCallURL, {
			method: 'GET',
			mode: 'cors',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status !== 'ok') {
					this.setState(() => ({
						message: 'Problém s připojením.',
					}));
					return;
				}
				this.setState(() => ({
					weeks: data.weeks,
					week_chosen: this.props.state.week_index != null,
					week_index: this.props.state.week_index == null ? -1 : this.props.state.week_index,
					weeks_loaded: true,
				}));
			});

		//! reference point
		const apiCallURL_new = process.env.REACT_APP_API_URL + '/weeks/v2/8';
		fetch(apiCallURL_new, {
			method: 'GET',
			mode: 'cors',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status !== 'ok') {
					this.setState(() => ({
						message: 'Problém s připojením.',
					}));
					return;
				}
				this.setState(() => ({
					weeks_new: data.weeks,
					weeks_loaded_new: true,
				}));
			});
	}

	calendar = () => {
		let calendar = [];

		const tomorrowDayInWeek = tomorrow().getDay() - 1; // -1 because week starts with monday not sunday
		for (let fillDay = 0; fillDay < tomorrowDayInWeek; fillDay++) {
			let newDay = {
				text: '',
			};
			calendar.push(newDay);
		}

		let day = new Date();
		for (let dayInCalendar = 0; dayInCalendar < 100; dayInCalendar++) {
			day = nextDay(day);
			let newDay = {
				text: day.getDate() + '.' + (day.getMonth() + 1), //month+1 because 0 indexed
			};
			calendar.push(newDay);
		}

		return calendar;
	};

	slots = () => {
		/* const capacity = {
      Po: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0],
      Ut: [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0],
      St: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
      Ct: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
      Pa: [2, 2, 2, 2, 2, 2, 2, 2, 1, 1, 0],
    }; */

		const capacity = this.state.capacity;

		//const capacityPalette = ["#FF9999", "#FFCC77", "#99DD55"];
		const capacityPalette = ['#FF4747', '#458F00', '#458F00'];

		const dayStart = 8;

		const duration = this.props.state.requested_custom_time ? this.props.state.service_hours : this.props.state.recommended_duration;

		let slots = [];
		let elementKey = 0;
		for (let index = 0; index < Math.min(11, 11 - duration) /* dayCapacity.length */; index++) {
			for (const day in capacity) {
				const dayCapacity = capacity[day];
				// 18 (day end) - 8 (day start) = 10 ..... - dur (so cleaning doesnt start late)
				let isFreeCapacity = 2;
				for (let dur = 0; dur < Math.ceil(duration); dur++) {
					if (dayCapacity.length <= index + dur) break;
					const hourCapacity = dayCapacity[index + dur];
					isFreeCapacity = Math.min(isFreeCapacity, hourCapacity);
				}
				const s = dayStart + index;
				const e = dayStart + index + duration;
				let slot = {
					key: elementKey,
					capacity: isFreeCapacity,
					color: capacityPalette[isFreeCapacity],
					start: s,
					end: e,
					day: day,
				};
				elementKey++;
				slots.push(slot);
			}
		}

		return slots;
	};

	click = (event) => {
		const dict = {
			Po: 'v pondělí',
			Ut: 'v úterý',
			St: 've středu',
			Ct: 've čtvrtek',
			Pa: 'v pátek',
			So: 'v sobotu',
			Ne: 'v neděli',
		};

		const dict_rev = {
			Po: 0,
			Ut: 1,
			St: 2,
			Ct: 3,
			Pa: 4,
			So: 5,
			Ne: 6,
		};

		this.setState({ chosenTime: event.target.textContent });
		const day = event.target.getAttribute('day');
		this.setState({
			day: dict[day],
			day_index: dict_rev[day],
			clickedOnWeekIndex: this.state.week_index,
		});
		this.props.setClickedOnWeekIndex(this.state.week_index);
		//global state
		this.props.setChosenTime({
			chosenTime: event.target.textContent,
			day: dict[day],
			date: this.state.weeks[this.state.week_index][dict_rev[day]],
		});
		event.stopPropagation();
	};

	chooseWeek = (event) => {
		let index = event.target.getAttribute('week');
		this.setState({
			week_chosen: true,
			week_index: index,
			capacities_loaded: false,
		});
		if (this.state.capacity == null) {
			this.setState({
				capacity: {
					Po: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					Ut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					St: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					Ct: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					Pa: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				},
			});
		}
		this.props.setWeek({
			week_index: index,
		});

		//! reference point
		const apiCallURL =
			process.env.REACT_APP_API_URL + '/week_capacity/v3/' +
			this.state.weeks[index][0].replace(/([0-9]*).([0-9]*). ([0-9]*)/gi, (str, day, month, year) => {
				return year + '-' + String(month).padStart(2, '0') + '-' + String(day).padStart(2, '0');
			});
		fetch(apiCallURL, {
			method: 'GET',
			mode: 'cors',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status !== 'ok') {
					this.setState(() => ({
						message: 'Problém s připojením.',
					}));
					return;
				}
				this.setState(() => ({
					capacity: data.capacity,
					capacities_loaded: true,
				}));
			});
	};

	render() {
		const handleChange = this.props.handleChange;
		const state = this.props.state;
		const local_state = this.state;
		const dates = this.calendar();
		const slots = this.slots();
		const hasFreeCapacity = slots.some(function (item) {
			return item.capacity > 0;
		});
		const click = this.click;
		const chosenTime = this.state.chosenTime;
		const day = this.state.day;
		const chooseWeek = this.chooseWeek;

		let duration = state.requested_custom_time ? state.service_hours : state.recommended_duration;
		duration = Math.max(duration, JSON.parse(localStorage.getItem('availability'))?.min_duration);

		const validateMe = this.props.myTab == this.props.currently_validating;

		const isNewPricing =
			this.state.weeks_loaded && this.state.week_chosen
				? this.state.weeks_new &&
				  Array.isArray(this.state.weeks_new) &&
				  this.state.weeks_new[parseInt(this.state.week_index)] &&
				  Array.isArray(this.state.weeks_new[parseInt(this.state.week_index)]) &&
				  this.state.weeks_new[parseInt(this.state.week_index)][0]
					? new Date(this.state.weeks_new[parseInt(this.state.week_index)][0]).getFullYear() > 2023
					: false
				: false;

		return (
			<div className="mb-16">
				<div>
					<Typography
						variant="h3"
						style={{ marginTop: 30, marginBottom: 20, fontFamily: 'graphik_medium, Arial', fontSize: 24, color: '#004D58', textAlign: 'left' }}
					>
						Vyberte si týden
					</Typography>
					{this.state.weeks_loaded ? (
						<Grid container style={{ width: state.mobile ? 'calc(100vw - 36px)' : 'initial' }}>
							{this.state.weeks?.map((week, week_index) => {
								return (
									<Grid item key={week[0].split(' ')[0]}>
										<Button
											onClick={chooseWeek}
											style={{ marginRight: '8px', marginBottom: '8px', fontFamily: 'graphik_regular, Arial', padding: '6px 12px' }}
											week={week_index}
											variant={week_index == this.state.week_index ? 'contained' : 'outlined'}
										>
											{week[0].split(' ')[0]}-{week[6].split(' ')[0]}
										</Button>
									</Grid>
								);
							})}
						</Grid>
					) : (
						<Grid container>
							{[1, 2, 3, 4, 5, 6, 7, 8].map((week, week_index) => (
								<Grid item key={week}>
									<Skeleton width={100} height={40} sx={{ margin: '0 10px' }} />
								</Grid>
							))}
						</Grid>
					)}

					{/* {isNewPricing &&
            <Alert severity="info" style={{ marginTop: 20 }}>
              Měníme ceny – držíme kvalitu. Od 1. 1. 2024 počítáme úklid dle nového ceníku.
            </Alert>
          } */}

					<FormControl error>
						<FormHelperText>{validateMe && state.week_index == null ? 'Prosím zvolte týden' : null}</FormHelperText>
					</FormControl>
				</div>
				<MainTheme>
					{this.state.week_chosen ? (
						<div>
							<PlanningForm
								duration={duration}
								weeks={local_state.weeks_new}
								weeks_old={local_state.weeks}
								week_index={local_state.week_index}
								setClickedOnWeekIndex={this.props.setClickedOnWeekIndex}
								setChosenTime={this.props.setChosenTime}
								state={this.props.state}
								mobile={state.mobile}
							/>
							<div>
								<FormControl error>
									<FormHelperText>
										{validateMe && (state.date == '' || state.chosenTime == '') ? 'Prosím zvolte čas a datum' : null}
									</FormHelperText>
								</FormControl>
								{state.chosenTime !== '' && state.chosenTime !== null && (
									<Typography variant="body1" style={{ marginBottom: 20 }}>
										Zvolený čas je {state.chosenTime} {state.day} ({this.props.state.date})
									</Typography>
								)}
							</div>
						</div>
					) : null}
				</MainTheme>
			</div>
		);
	}
}

export default ScheduleForm;
