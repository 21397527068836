import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { LogSnag } from 'logsnag';
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function Finish(props) {
	const navigate = useNavigate();

	const [state, setState] = useState({
		loading: false,
	});

	const sendOrder = async () => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		setState({ loading: true });
		//! reference point
		let response = await fetch(process.env.REACT_APP_API_URL + '/new-order/' + uid + '?token=' + token, {
			method: 'POST',
			headers: {
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				address: {
					zip: props.state.zip,
					street: props.state.street,
					cp: props.state.street_number,
					city: props.state.city,
					zone_place_id: JSON.parse(localStorage.getItem('availability'))?.zone_place_id ?? 'NEVYPLNENO-FINISH',
				},
				apartment: {
					beds: props.state.beds,
					bathrooms: props.state.bathrooms,
					phone: props.state.apartment_phone,
					user_name: props.state.user_name,
				},
				invoice: {
					name: props.state.invoice_name,
					address: props.state.invoice_address,
					cp: props.state.invoice_cp,
					city: props.state.invoice_city,
					zip: props.state.invoice_psc,
					country: props.state.invoice_country,
					ic: props.state.invoice_ic,
					dic: props.state.invoice_dic,
					//email: props.state.invoice_email,
					phone: props.state.invoice_tel,
				},
				order: {
					requested_custom_time: props.state.requested_custom_time,
					duration: props.state.requested_custom_time ? props.state.service_hours : props.state.recommended_duration,
					weekday: props.state.day,
					date: props.state.date,
					start: props.state.chosenTime?.split('-')[0],
					frequency: props.state.frequency,
					prepaid: props.state.prepaid,
					extras: props.state.extras,
					note: props.state.note,
					coupon: props.state.coupon,
				},
				additional: {
					cleaning_package: props.state.cleaning_package == 'true',
				},
			}),
		}).then((response) => response.json());

		localStorage.setItem('duration', props.state.requested_custom_time ? props.state.service_hours : props.state.recommended_duration);
		localStorage.setItem('cleaning_package', props.state.cleaning_package == 'true');
		localStorage.setItem('coupon', props.state.coupon_active);
		localStorage.setItem('coupon_scalar', props.state.coupon_scalar);
		localStorage.setItem('works_on_package', props.state.coupon_active_on_package);
		localStorage.setItem('coupon_package_discount', props.state.coupon_package_discount);
		localStorage.setItem(
			'date',
			props.state.date.replace(/([0-9]*).([0-9]*). ([0-9]*)/gi, (str, day, month, year) => {
				return year + '-' + String(month).padStart(2, '0') + '-' + String(day).padStart(2, '0');
			})
		);
		localStorage.setItem('frequency', props.state.frequency);

		if (response.status == 'ok') {
			localStorage.setItem('first_time', 'no');
			//setState({ loading: false });
		}

		// logging funnel
		await sng.publish({
			project: 'youklid-funnel',
			channel: 'new-user-flow',
			event: 'FILLED',
			icon: '🆗',
			notify: false,
			tags: {
				tid: localStorage.getItem('tid') || 'UNKNOWN',
			},
		});

		//console.log(response);
		const costs = response?.total;
		localStorage.setItem('costs', costs);
		localStorage.setItem('costs_note', 'If you change costs, it wont be cheaper, I am not dumb.');

		const order_id = response?.order_id;
		localStorage.setItem('last_order_id', order_id);

		// redirect_to_profile
		let dest = response.status == 'ok' ? '/odeslano' : '/chyba';
		dest = response.redirect_to_profile == true ? '/profil' : dest;
		navigate(dest);
	};

	return (
		<>
			{!state.loading ? (
				<Button
					variant="contained"
					onClick={sendOrder}
					style={{
						width: '100%',
						boxShadow: 'none',
						backgroundColor: '#8c5cbc',
						borderColor: '#8c5cbc',
						fontSize: 20,
						fontFamily: 'agrandir',
						textTransform: 'initial',
						borderRadius: 100,
						marginBottom: 20,
					}}
				>
					<span style={{ position: 'relative', top: 2 }}>Dokončit objednávku</span>
				</Button>
			) : (
				<Button
					variant="contained"
					style={{
						width: '100%',
						height: 47,
						backgroundColor: '#8c5cbc',
						borderColor: '#8c5cbc',
						borderRadius: '400px',
						fontFamily: 'agrandir',
						textTransform: 'initial',
						fontSize: 20,
						boxShadow: 'none',
					}}
					disabled
				>
					<CircularProgress size="24px" style={{ color: '#fff' }} />
				</Button>
			)}
		</>
	);
}

export default Finish;
