import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import LogSnag from 'logsnag';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function LeadForm(props) {
	let navigate = useNavigate();
	const [suggestions, setSuggestions] = useState([
		//* example only
		// { "label": "Praha, Hlavní město Praha", "zone_id": 1 },
		// { "label": "Prachatice, Jihočeský kraj", "zone_id": 439 },
		// { "label": "Pravlov, Jihomoravský kraj", "zone_id": 1568 },
		// { "label": "Prace, Jihomoravský kraj", "zone_id": 1273 },
		// { "label": "Praskolesy, Středočeský kraj", "zone_id": 1075 }
	]);
	const [validation, setValidation] = useState({
		locationSet: false,
		emailSet: false,
		locationMessage: null,
		emailMessage: null,
	});
	const [errorMessage, setErrorMessage] = useState(null);

	const autocomplete = (city_prefix) => {
		//! reference point
		const url = `${process.env.REACT_APP_API_URL}/autocomplete/v3?prefix=${city_prefix}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				console.log(data);
				if (data.status == 'ok') {
					setSuggestions(data.zones);
					setErrorMessage(null);
				} else {
					setSuggestions([]);
					throw new Error(data.message);
				}
			})
			.catch((error) => {
				switch (error.message) {
					case 'network':
						setErrorMessage('Problém se sítí.');
						break;

					default:
						console.log(error);
						break;
				}
			});
	};

	const zone_details = (zone_id) => {
		console.log(zone_id);
	};

	const place_details = (place_id) => {
		if (place_id == null || place_id == '') console.error('No place_id provided from selected city.');

		//! reference point
		const url = `${process.env.REACT_APP_API_URL}/place/v2/${place_id}`;
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				if (data.status != 'ok') {
					throw new Error(data.message);
				}

				return {
					place_id: place_id,
					postal_code: data.place?.postal_code ? data.place.postal_code.replaceAll(' ', '') : null,
					city_name: data.place.locality ?? data.place.neighborhood ?? data.place.sublocality_level_1,
				};
			})
			.then((data) => verify_availability(data.place_id, data.postal_code, data.city_name))
			.then((result) => {
				//console.log(result);
				let availability = {
					zone_id: null,
					actively_cleaning: false,
					min_duration: null,
					travel_fee: null,
					open_since: '2099-12-31',
					zip: null,
					city_name: null,
					zone_place_id: null,
				};
				if (result.status == 'ok') {
					availability.zone_id = result.zone_id;
					availability.actively_cleaning = result.actively_cleaning;
					availability.min_duration = result.min_duration;
					availability.travel_fee = result.travel_fee;
					availability.open_since = result.open_since;
					availability.zip = result.zip;
					availability.city_name = result.city_name;
					availability.zone_place_id = result.zone_place_id;
				}
				localStorage.setItem('availability', JSON.stringify(availability));
				setValidation((validation) => ({
					...validation,
					...{
						locationSet: true,
					},
				}));
			});
	};

	const verify_availability = (place_id, postal_code, city_name) => {
		//! reference point
		const url = `${process.env.REACT_APP_API_URL}/verify_city/${place_id}/${postal_code}/${city_name}`;
		const response = fetch(url).then((response) => response.json());

		return response;
	};

	const test_email = (e) => {
		const email = e.target.value.replaceAll(' ', '');
		let email_ok = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
		setValidation((validation) => ({
			...validation,
			...{
				emailSet: true,
				emailMessage: email_ok ? null : 'Špatně zadaný email',
			},
		}));
		localStorage.setItem('email', email);
	};

	const goto_order = () => {
		if (!validation.locationSet || !validation.emailSet || validation.locationMessage !== null || validation.emailMessage !== null) return;

		const tid = localStorage.getItem('tid');
		const email = localStorage.getItem('email');
		const zip = JSON.parse(localStorage.getItem('availability'))?.zip;
		const city_name = JSON.parse(localStorage.getItem('availability'))?.city_name;
		const availability = localStorage.getItem('availability');
		// funnel measuring
		async function sendLog() {
			await sng.publish({
				project: 'youklid-funnel',
				channel: 'new-user-flow',
				event: 'LEAD',
				description: zip + ' ' + email,
				icon: '📨',
				notify: false,
				tags: {
					tid: localStorage.getItem('tid') || 'UNKNOWN',
					zip: zip,
					email: email,
				},
			});
		}
		sendLog();
		// end
		localStorage.clear();
		localStorage.setItem('tid', tid);
		localStorage.setItem('email', email);
		localStorage.setItem('zip', zip);
		localStorage.setItem('city_name', city_name);
		localStorage.setItem('availability', availability);

		navigate('/objednavka');
	};

	return (
		<>
			<Grid container justifyContent="center" alignItems="flex-start" direction="row">
				<Grid item>
					<Autocomplete
						disablePortal
						color="secondary"
						label="Město / Obec"
						openText="Ukázat možnosti"
						closeText="Zavřít"
						style={{
							backgroundColor: '#FBFDFC',
							margin: '10px',
							width: 318,
							borderRadius: '6px',
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={errorMessage != null}
								helperText={errorMessage}
								label="Město / Obec"
								onChange={(e) => {
									autocomplete(e.target.value);
								}}
							/>
						)}
						options={suggestions}
						// onChange={(e, value) => place_details(value?.place_id)}
						onChange={(e, value) => zone_details(value.zone_id)}
						noOptionsText={'Zadejte město'}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						style={{
							backgroundColor: '#FBFDFC',
							margin: '10px',
							width: 318,
							borderRadius: '6px',
						}}
						label="Email"
						error={validation.emailMessage !== null}
						helperText={validation.emailMessage}
						onChange={test_email}
					/>
				</Grid>
				<Grid item>
					<Button
						variant="filled"
						style={{
							color: '#FBFDFC',
							backgroundColor: '#8C5CBC',
							height: '56px',
							fontSize: 20,
							fontFamily: 'agrandir',
							margin: '10px',
							width: 318,
							borderRadius: 500,

							borderColor: '#8C5CBC',
							textTransform: 'initial',
							padding: '10px 34px',
							boxShadow: 'none',
						}}
						onClick={goto_order}
					>
						<span style={{ position: 'relative', top: '2px' }}>Kalkulace a objednávka</span>
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
export default LeadForm;
