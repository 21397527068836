import React, { Component } from 'react';
import './App.css';
import BasicInfoForm from './BasicInfoForm';
import FrequencyForm from './FrequencyForm';
import ScheduleForm from './ScheduleForm';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import PersonalInfo from './PersonalInfo';
import Menu from './Menu';
import { Alert, Button, Grid, makeStyles, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TabContext, TabPanel } from '@mui/lab';
import Finish from './Finish';
import Summary from './Summary';
import FormValidation from './FormValidation';
import MobileTooltip from './MobileTooltip';
import { Navigate } from 'react-router-dom';
import HelpButton from './HelpButton';
import { isMobile } from 'react-device-detect';
import FloatingPrice from './FloatingPrice';
import MainTheme from './Wrapper/MainTheme';
import { LogSnag } from 'logsnag';
import FormIntro from './FormIntro';
import ArrowRight from './ArrowRight';
import FetchInvoiceInfo from './FetchInvoiceInfo';
import { Default, IsMobile } from './lib/Responsive';
import PriceTest from './PriceTest';

const sng = new LogSnag({
	token: '81abed701eaa04e35ba21622e0f784a9',
	project: 'youklid-funnel',
});

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tab: '1',
			redirect: false,

			zip: localStorage.getItem('zip') == '' || localStorage.getItem('zip') == null ? '' : localStorage.getItem('zip'),
			street: '',
			street_number: '',
			city: localStorage.getItem('city_name') == '' || localStorage.getItem('city_name') == null ? '' : localStorage.getItem('city_name'),
			rooms: 1,
			beds: 1,
			bathrooms: 1,
			apartment_phone: '',
			frequency: '',
			prepaid: '',
			date: '',
			extras: [
				//{ label: "Mytí oken", selected: false, estimate: 2.0 },
				{
					label: 'Žehlení',
					selected: false,
					estimate: 1.5,
					description: 'Do plánované doby úklidu zahrneme navíc přibližný čas potřebný na vyžehlení prádla.',
				},
				{
					label: 'Praní prádla',
					selected: false,
					estimate: 0.5,
					description: 'Do plánované doby úklidu zahrneme navíc přibližný čas potřebný na vyprání či věšení prádla.',
				},
				{
					label: 'Vnitřek lednice',
					selected: false,
					estimate: 0.5,
					description: 'Pokud nechcete vyhodit prošlé věci z lednice, napiště prosím do poznámky.',
				},
				{
					label: 'Mytí oken',
					selected: false,
					estimate: 0,
					description: '⚠ Doplňková služba.',
					is_extra: true,
				},
				{
					label: 'Čištění koberců',
					selected: false,
					estimate: 0,
					description: '⚠ Doplňková služba.',
					is_extra: true,
				},
				{
					label: 'Čištění čalounění',
					selected: false,
					estimate: 0,
					description: '⚠ Doplňková služba.',
					is_extra: true,
				},
				/* {
          label: "Vnitřek trouby",
          selected: false,
          estimate: 1,
          description: "Důkladné vyčištění a odmaštění vnitřních částí trouby",
        }, */
			],
			show_address: false,
			show_contact_popup: false,
			recommended_duration: 3,
			chosenTime: '',
			day: '',
			requested_custom_time: false,
			service_hours: 3,
			user_name: '',
			invoice_name: '',
			invoice_address: '',
			invoice_cp: '',
			invoice_city: localStorage.getItem('city_name') == '' || localStorage.getItem('city_name') == null ? '' : localStorage.getItem('city_name'),
			invoice_psc: localStorage.getItem('zip') == '' || localStorage.getItem('zip') == null ? '' : localStorage.getItem('zip'),
			invoice_country: 'CZE',
			invoice_ic: '',
			invoice_dic: '',
			invoice_email: '',
			invoice_tel: '',

			coupon: '',
			coupon_active: false,
			coupon_scalar: 0,
			coupon_active_on_package: false,
			coupon_package_discount: 0,

			note: '',
			company_invoice: false,

			cleaning_package: false,
			has_basic_equipment: false,

			width: window.innerWidth,

			filled: [],
			currently_validating: 0,

			we_are_not_cleaning_here: false,
			mobile: isMobile,

			slot_id: null,

			first_time: true,

			ic_fill_error: null,
		};

		window.addEventListener('resize', () => {
			this.setState({ width: window.innerWidth });
		});
	}

	testTab = (tab) => {
		let filled = this.state.filled;
		//console.log(this.state);
		const validated = {
			zip_ok: this.state.zip.length > 0,
			street_ok: this.state.street.length > 0,
			street_number_ok: this.state.street_number.length > 0,
			city_ok: this.state.city.length > 0 || true,
			apartment_basic_equipment_ok: this.state.has_basic_equipment, //TODO
			apartment_package_ok: true, //TODO
			apartment_tel_ok: this.state.apartment_phone.length > 0,
			frequency_ok: this.state.frequency.length > 0,
			prepaid_ok: this.state.prepaid.length > 0,
			date_ok: this.state.date.length > 0,
			chosenTime_ok: this.state.chosenTime.length > 0,
			end_client_invoicing: this.state.company_invoice == false,
			user_name_ok: this.state.user_name.length > 0,
			invoice_name_ok: this.state.invoice_name != null && this.state.invoice_name.length > 0,
			invoice_address_ok: this.state.invoice_address != null && this.state.invoice_address.length > 0,
			invoice_cp_ok: this.state.invoice_cp != null && this.state.invoice_cp.length > 0,
			invoice_city_ok: this.state.invoice_city != null && this.state.invoice_city.length > 0,
			invoice_psc_ok: this.state.invoice_psc != null && this.state.invoice_psc.length > 0,
			invoice_country_ok: this.state.invoice_country != null && this.state.invoice_country.length > 0,
			/*invoice_email_ok:
        this.state.invoice_email.length > 0 ||
        localStorage.getItem("first_time") === "no",*/
			invoice_tel_ok: (this.state.invoice_tel != null && this.state.invoice_tel.length > 0) || localStorage.getItem('first_time') === 'no',
		};

		// TAB 1
		let validating_tab = 1;
		if (true) {
			this.setFilled(validating_tab);
			filled = [...filled, validating_tab];
		} else {
			this.removeFilled(validating_tab);
			filled = filled.filter((t) => t != validating_tab);
		}

		// TAB 2
		// validating_tab = 2;
		// if (validated.zip_ok && validated.street_ok && validated.street_number_ok && validated.city_ok) {
		//   this.setFilled(validating_tab);
		//   filled = [...filled, validating_tab];
		// } else {
		//   this.removeFilled(validating_tab);
		//   filled = filled.filter((t) => t != validating_tab);
		// }
		//console.log(validated);
		// TAB 2
		validating_tab = 2;
		if (
			validated.apartment_basic_equipment_ok &&
			validated.apartment_package_ok &&
			validated.zip_ok &&
			validated.street_ok &&
			validated.street_number_ok &&
			(validated.apartment_tel_ok || localStorage.getItem('first_time') == 'no') &&
			(validated.invoice_name_ok || localStorage.getItem('first_time') == 'no')
			/*  && validated.city_ok */
		) {
			this.setFilled(validating_tab);
			filled = [...filled, validating_tab];
		} else {
			this.removeFilled(validating_tab);
			filled = filled.filter((t) => t != validating_tab);
		}

		//TAB 3
		validating_tab = 3;
		if (validated.frequency_ok) {
			this.setFilled(validating_tab);
			filled = [...filled, validating_tab];
		} else {
			this.removeFilled(validating_tab);
			filled = filled.filter((t) => t != validating_tab);
		}

		//TAB 4
		validating_tab = 4;
		if (validated.date_ok && validated.chosenTime_ok) {
			this.setFilled(validating_tab);
			filled = [...filled, validating_tab];
		} else {
			this.removeFilled(validating_tab);
			filled = filled.filter((t) => t != validating_tab);
		}

		//TAB 5
		validating_tab = 5;
		if (
			validated.end_client_invoicing ||
			(validated.invoice_name_ok &&
				validated.invoice_address_ok &&
				validated.invoice_cp_ok &&
				validated.invoice_city_ok &&
				validated.invoice_psc_ok &&
				validated.invoice_country_ok &&
				//validated.invoice_email_ok &&
				validated.invoice_tel_ok)
		) {
			this.setFilled(validating_tab);
			filled = [...filled, validating_tab];
		} else {
			this.removeFilled(validating_tab);
			filled = filled.filter((t) => t != validating_tab);
		}

		for (let index = 0; index < filled.length; index++) {
			const element = filled[index];
			if (element == tab) return true;
		}
		return false;
	};

	clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	prev = (event) => {
		let nextTab = this.clamp(parseInt(this.state.tab) - 1, 1, 7).toString();
		if (this.state.tab == 6) {
			nextTab = this.clamp(parseInt(this.state.tab) - 2, 1, 7).toString();
		}
		this.setState((state) => ({
			tab: nextTab,
		}));
		window.scrollTo(0, 0);
	};

	next = (event) => {
		this.setState((state) => ({ currently_validating: state.tab }));
		if (this.testTab(this.state.tab)) {
			let nextTab = this.clamp(parseInt(this.state.tab) + 1, 1, 7).toString();
			if (this.state.tab == 4) {
				nextTab = this.clamp(parseInt(this.state.tab) + 2, 1, 7).toString();
			}
			this.setState((state) => ({
				tab: nextTab,
			}));
			window.scrollTo(0, 0);
			switch (this.state.tab) {
				case '1':
					async function sendLog1() {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'INFORMED',
							icon: '👨‍🎓',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
							},
						});
					}
					sendLog1();
					break;
				case '2':
					async function sendLog2() {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'ADDRESS',
							icon: '🏠',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
							},
						});
					}
					sendLog2();
					break;
				case '3':
					async function sendLog3(phone) {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'PHONE',
							description: phone,
							icon: '📞',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
								phone: phone,
							},
						});
					}
					sendLog3(this.state.apartment_phone);
					break;
				case '4':
					async function sendLog4() {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'FREQ',
							icon: '🔄',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
							},
						});
					}
					sendLog4();
					break;
				case '5':
					async function sendLog5() {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'DATE',
							icon: '📅',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
							},
						});
					}
					sendLog5();
					break;
				case '6':
					async function sendLog6() {
						await sng.publish({
							project: 'youklid-funnel',
							channel: 'new-user-flow',
							event: 'INVOICE',
							icon: '📑',
							notify: false,
							tags: {
								tid: localStorage.getItem('tid') || 'UNKNOWN',
							},
						});
					}
					sendLog6();
					break;
				default:
					break;
			}
		}
	};

	setFilled = (tab) => {
		this.setState((state) => ({ filled: [...state.filled, tab] }));
	};

	removeFilled = (tab) => {
		this.setState((state) => ({
			filled: state.filled.filter((t) => t != tab),
		}));
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	setExtras = (extras) => {
		this.setState({
			extras: extras,
		});
	};

	setShowAddress = (show_address) => {
		this.setState({
			show_address: show_address,
		});
	};

	setShowContact = (show_contact_popup) => {
		this.setState({
			show_contact_popup: show_contact_popup,
		});
	};

	setDuration = (duration) => {
		this.setState({
			recommended_duration: duration,
		});
	};

	setChosenTime = (data) => {
		this.setState((state) => ({
			chosenTime: data.chosenTime,
			day: data.day,
			date: data.date,
			slot_id: data.slot_id,
			service_hours: Math.max(state.service_hours, JSON.parse(localStorage.getItem('availability'))?.min_duration),
			recommended_duration: Math.max(state.recommended_duration, JSON.parse(localStorage.getItem('availability'))?.min_duration),
		}));
	};

	setCustomDuration = (event) => {
		if (event.target.type === 'button' && event.target.getAttribute('l') === 'button') {
			this.setState({
				requested_custom_time: true,
			});
		} else if (event.target.type === 'button' && event.target.getAttribute('l') === 'recommended') {
			this.setState({
				requested_custom_time: false,
			});
		} else {
			this.setState({
				service_hours: event.target.value,
			});
		}
	};

	setCountry = (value) => {
		this.setState({
			invoice_country: value,
		});
	};

	setWeek = (data) => {
		this.setState({
			week_index: data.week_index,
		});
	};

	setCompanyInvoiceInfo = (data) => {
		this.setState({
			//invoice_ic: data.ic,
			invoice_dic: data.dic,
			invoice_name: data.name,
			invoice_address: data.street,
			invoice_cp: data.streetNumber,
			invoice_city: data.city,
			invoice_psc: data.zip,
		});
	};

	setInvoiceInfoFromApi = (data) => {
		this.setState({
			invoice_ic: data.ic,
			invoice_dic: data.dic,
			invoice_name: data.name,
			invoice_address: data.street,
			invoice_cp: data.streetNumber,
			invoice_city: data.city,
			invoice_psc: data.zip,
		});
	};

	setICError = (message) => {
		this.setState({
			ic_fill_error: message,
		});
	};

	validateCredentials = () => {
		//! reference point
		fetch(process.env.REACT_APP_API_URL + '/users/' + localStorage.getItem('uid') + '?token=' + localStorage.getItem('token')).then((response) => {
			if (response.status != 200) {
				// redirect to /login
				this.setState({
					redirect: {
						status: true,
						destination: '/login',
						message: 'Relace vypršela, prosím přihlašte se',
					},
				});
				localStorage.clear();
			}
		});
	};

	createUser = () => {
		//console.log(document.referrer);
		let zip = localStorage.getItem('zip');
		zip = zip == '' ? 'null' : zip;
		const place_id = JSON.parse(localStorage.getItem('availability'))?.zone_place_id;
		//! reference point
		fetch(
			process.env.REACT_APP_API_URL + '/users?zip=' +
				zip +
				'&email=' +
				localStorage.getItem('email') +
				'&referer=' +
				(document.referrer == '' || document.referrer == null ? 'none' : document.referrer) +
				'&city_name=' +
				localStorage.getItem('city_name') +
				'&place_id=' +
				place_id,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers': '*',
					'Access-Control-Allow-Methods': '*',
				},
			}
		)
			.then((response) => {
				if (response.status === 200) return response;

				if (response.status === 409) {
					localStorage.removeItem('email');
					throw new Error('Tento email už někdo používá.');
				}

				if (response.status === 500) throw new Error('Problém na serveru');

				throw new Error('Nastala nějaká chyba');
			})
			.then((response) => response.json())
			.then((data) => {
				localStorage.setItem('uid', data.user.user_id);
				localStorage.setItem('token', data.user.token);
				localStorage.setItem('first_time', 'yes');
				this.setState({ first_time: true });
			})
			.catch((e) => {
				this.setState({
					redirect: {
						status: true,
						destination: '/',
						message: e.message,
					},
					error_message: e.message,
				});
			});
	};

	componentDidMount() {
		this.getServices();

		this.setState({
			invoice_city: localStorage.getItem('city_name'),
			invoice_psc: localStorage.getItem('zip'),
		});

		window.scrollTo(0, 0);
		const uidAndTokenGood = localStorage.getItem('uid') && localStorage.getItem('token');
		const emailAndZipGood = localStorage.getItem('email') && localStorage.getItem('zip') != null;
		//console.log("uid" + localStorage.getItem("uid"));
		//console.log("token" + localStorage.getItem("token"));
		//console.log("zip" + localStorage.getItem("zip"));
		//console.log("email" + localStorage.getItem("email"));
		//console.log(uidAndTokenGood, emailAndZipGood);

		//check if logged in
		if (uidAndTokenGood) {
			// validate local uid & token against DB
			this.validateCredentials();
		} else {
			// token or uid not in local storage
			if (emailAndZipGood) {
				//create user form landing page email & token
				this.createUser();
			} else {
				//redirect to login
				this.setState({
					redirect: {
						status: true,
						destination: '/login',
						message: 'Nastala chyba, prosím přihlašte se',
					},
				});
			}
		}
	}

	wantCleaningPackage = (wants) => {
		this.setState({ cleaning_package: wants });
	};
	hasBasicEquipment = (has) => {
		this.setState({ has_basic_equipment: has });
	};
	setClickedOnWeekIndex = (index) => {
		this.setState({ clickedOnWeekIndex: index });
	};

	setCompanyInvoice = (value) => {
		this.setState({ company_invoice: value });
	};

	setNotCleaningHere = (value) => {
		this.setState({ we_are_not_cleaning_here: value });
	};

	setCouponActive = (value) => {
		this.setState({
			coupon_active: value,
		});
	};

	setCouponActiveGeneric = (configuration) => {
		this.setState(configuration);
	};

	setStreet = (e) => {
		if (localStorage.getItem('first_time') == 'no') return;
		this.setState({
			invoice_address: e.target.value,
		});
	};

	setNumber = (e) => {
		if (localStorage.getItem('first_time') == 'no') return;
		this.setState({
			invoice_cp: e.target.value,
		});
	};

	setCity = (e) => {
		if (localStorage.getItem('first_time') == 'no') return;
		this.setState({
			invoice_city: e.target.value,
		});
	};

	setZip = (e) => {
		if (localStorage.getItem('first_time') == 'no') return;
		this.setState({
			invoice_psc: e.target.value,
		});
	};

	setPhone = (e) => {
		this.setState({
			invoice_tel: e.target.value,
		});
	};

	setName = (e) => {
		if (localStorage.getItem('first_time') == 'no') return;
		// if (this.state.invoice_name.length <= 0) {
		//   this.setState({
		//     invoice_name: e.target.value,
		//   });
		// }
		this.setState({
			invoice_name: e.target.value,
		});
	};

	getServices = () => {
		const zone_id = JSON.parse(localStorage.getItem('availability'))?.zone_id;
		const url = `${process.env.REACT_APP_API_URL}/services?zone_id=${zone_id}`;

		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				const services = Object.values(data).map((service) => {
					return {
						id: service.catalog_service_id,
						label: service.catalog_service_name,
						selected: false,
						estimate: service.catalog_service_estimate,
						description: service.catalog_service_checklist,
						is_extra: service.catalog_service_difficulty >= 10,
					};
				});

				this.setState({
					extras: services,
				});
			});
	};

	render() {
		const { tab, currently_validating } = this.state;
		const next = this.next;
		const prev = this.prev;
		const handleChange = this.handleChange;
		const state = this.state;
		const setShowAddress = this.setShowAddress;
		const setShowContact = this.setShowContact;
		const setExtras = this.setExtras;
		const setDuration = this.setDuration;
		const setChosenTime = this.setChosenTime;
		const setCustomDuration = this.setCustomDuration;
		const setCountry = this.setCountry;
		const setWeek = this.setWeek;
		const wantCleaningPackage = this.wantCleaningPackage;
		const hasBasicEquipment = this.hasBasicEquipment;
		const setClickedOnWeekIndex = this.setClickedOnWeekIndex;
		const setCompanyInvoice = this.setCompanyInvoice;
		const setFilled = this.setFilled;
		const setNotCleaningHere = this.setNotCleaningHere;
		const setCouponActive = this.setCouponActive;
		const setCouponActiveGeneric = this.setCouponActiveGeneric;
		const setCompanyInvoiceInfo = this.setCompanyInvoiceInfo;
		const setICError = this.setICError;
		const setStreet = this.setStreet;
		const setNumber = this.setNumber;
		const setCity = this.setCity;
		const setZip = this.setZip;
		const setPhone = this.setPhone;
		const setName = this.setName;

		if (this.state.redirect.status) {
			this.state.redirect.status = false;
			const msg = this.state.redirect.message;
			const dest = this.state.redirect.destination;
			localStorage.setItem('error_msg', msg);
			return <Navigate to={dest} />;
		}

		if (localStorage.getItem('first_time') === 'no') {
			const availability = localStorage.getItem('availability');
			const region_id = JSON.parse(localStorage.getItem('availability'))?.region_id;
			if (region_id === undefined) {
				localStorage.removeItem('availability');
			}
		}

		const first_time = localStorage.getItem('first_time') === 'yes';
		const actively_cleaning = JSON.parse(localStorage.getItem('availability'))?.actively_cleaning == true;

		const service_ids = state.extras
			.filter((service) => service.selected)
			.map((service) => service.id)
			.concat(state.cleaning_package === 'true' ? [10001] : [])
			.filter((value, index, self) => self.indexOf(value) === index);

		const duration = Math.max(
			JSON.parse(localStorage.getItem('availability'))?.min_duration,
			state.requested_custom_time ? state.service_hours : state.recommended_duration
		);

		return (
			<div
				style={{
					background: '#004250',
					minHeight: '100vh',
					paddingTop: state.tab != 1 && state.tab != 2 && state.tab != 7 ? /*75*/ 0 : 'inherit',
				}}
			>
				{/* floating elements */}
				{/* <HelpButton /> */}
				{/* <FloatingPrice state={state} /> */}
				{isMobile && state.tab != 1 && state.tab != 6 && (
					<PriceTest
						availability_loaded={localStorage.getItem('availability') !== null}
						zone_id={JSON.parse(localStorage.getItem('availability'))?.zone_id}
						duration={duration}
						services={service_ids}
						coupon={state.coupon}
						user_id={localStorage.getItem('uid')}
						token={localStorage.getItem('token')}
					/>
				)}
				<FetchInvoiceInfo setInvoiceInfoFromApi={this.setInvoiceInfoFromApi} />

				<Menu />
				<div
					style={{
						background: '#FBFDFC',
						borderRadius: state.mobile ? '20px 20px 0 0' : 20,
						padding: state.mobile ? '24px 24px 64px 24px' : 60,
						marginLeft: state.mobile ? 0 : 64,
						marginRight: state.mobile ? 0 : 64,
						minHeight: state.mobile ? '100vh' : 'inherit',
					}}
				>
					{state.width > 850 ? (
						<Stepper activeStep={tab - 1} completed={'false'} alternativeLabel>
							<Step>
								<StepLabel>
									<Typography
										variant="h5"
										style={{
											color: '#004d58',
											fontFamily: 'agrandir',
										}}
									>
										Informace
									</Typography>
								</StepLabel>
							</Step>
							{/* <Step>
                <StepLabel>
                  <Typography variant="h5">
                    Lokalita
                  </Typography>
                </StepLabel>
              </Step> */}
							<Step>
								<StepLabel>
									<Typography
										variant="h5"
										style={{
											color: '#004d58',
											fontFamily: 'agrandir',
										}}
									>
										Kalkulace
									</Typography>
								</StepLabel>
							</Step>
							<Step>
								<StepLabel>
									<Typography
										variant="h5"
										style={{
											color: '#004d58',
											fontFamily: 'agrandir',
										}}
									>
										Frekvence
									</Typography>
								</StepLabel>
							</Step>
							<Step>
								<StepLabel>
									<Typography
										variant="h5"
										style={{
											color: '#004d58',
											fontFamily: 'agrandir',
										}}
									>
										Datum
									</Typography>
								</StepLabel>
							</Step>
							{/* {state.company_invoice ?
                <Step>
                  <StepLabel>
                    <Typography variant="h5">Osobní údaje</Typography>
                  </StepLabel>
                </Step>
                : null} */}
							<Step>
								<StepLabel>
									<Typography
										variant="h5"
										style={{
											color: '#004d58',
											fontFamily: 'agrandir',
										}}
									>
										Shrnutí
									</Typography>
								</StepLabel>
							</Step>
						</Stepper>
					) : null}
					<div
						className={state.width > 850 ? 'mx-auto mt-12' : 'mx-auto max-w-2xl'}
						style={{
							position: 'relative',
							maxWidth: 900,
						}}
					>
						<Typography
							variant="h1"
							style={{
								fontFamily: 'agrandir',
								fontSize: state.mobile ? 38 : 64,
								textAlign: state.mobile ? 'center' : 'inherit',
								color: '#004250',
								marginBottom: 20,
							}}
						>
							{state.tab == 1
								? 'S námi je to snadné!'
								: state.tab == 2
								? 'Kalkulace'
								: state.tab == 3
								? 'Frekvence'
								: state.tab == 4
								? 'Datum'
								: state.tab == 5
								? 'Osobní údaje'
								: state.tab == 6
								? 'Shrnutí'
								: ''}
						</Typography>
						<Typography
							variant="body1"
							style={{
								fontFamily: 'graphik_regular',
								fontSize: 16,
								textAlign: state.mobile ? 'center' : 'inherit',
								color: 'rgb(0, 66, 80, 0.7)',
								marginBottom: 20,
							}}
						>
							{state.tab == 1 ? (
								<>
									Ať už vám jde o nezávaznou kalkulaci, nebo si chcete rezervovat i konkrétní termín úklidu, nezabere vám to více jak
									5&nbsp;minut.
								</>
							) : state.tab == 2 ? (
								<>
									Konec zdlouhavému domlouvání! Díky naší kalkulačce vám spočítáme délku i cenu úklidu na míru každé domácnosti. Případné
									přeplatky či nedoplatky společně vyřešíme po skončení úklidu.
								</>
							) : state.tab == 3 ? (
								'Vyberte si vyhovující frekvenci úklidu.'
							) : state.tab == 4 ? (
								'Vyberte si, kdy vaši domácnost navštívíme.'
							) : state.tab == 5 ? (
								'Vyplňte prosím údaje o vás.'
							) : state.tab == 6 ? (
								'Zkontrolujte, prosím, zda je všechno v pořádku.'
							) : (
								''
							)}
						</Typography>
						<div
							style={{
								width: '100%',
								borderBottom: '1px solid #f0f5f5',
							}}
						></div>
						<Grid container justifyContent={'center'}>
							<Grid container justifyContent={'center'} gap={8} alignItems={'stretch'} style={{ maxWidth: 900 }}>
								<Grid item flex={6} className={state.width > 850 ? 'mx-auto max-w-2xl mt-12' : ''}>
									<Box sx={{ typography: 'body1' }}>
										<TabContext value={tab}>
											<TabPanel style={{ padding: 0 }} value="1">
												<FormIntro first_time={this.state.first_time} />
											</TabPanel>
											{/* <TabPanel style={{ padding: 0 }} value="2">
                  <AddressForm handleChange={handleChange} state={state} setShowAddress={setShowAddress} setShowContact={setShowContact} setNotCleaningHere={setNotCleaningHere} currently_validating={currently_validating} myTab={2} />
                </TabPanel> */}
											<TabPanel style={{ padding: 0 }} value="2">
												<BasicInfoForm
													myTab={2}
													currently_validating={currently_validating}
													state={state}
													handleChange={handleChange}
													wantCleaningPackage={wantCleaningPackage}
													hasBasicEquipment={hasBasicEquipment}
													setDuration={setDuration}
													setCustomDuration={setCustomDuration}
													setExtras={setExtras}
													setCouponActive={setCouponActive}
													setCouponActiveGeneric={setCouponActiveGeneric}
													setCompanyInvoice={setCompanyInvoice}
													setStreet={setStreet}
													setNumber={setNumber}
													setCity={setCity}
													setZip={setZip}
													setPhone={setPhone}
													setName={setName}
													setCompanyInvoiceInfo={setCompanyInvoiceInfo}
													setICError={setICError}
												/>
											</TabPanel>
											<TabPanel style={{ padding: 0 }} value="3">
												<FrequencyForm
													handleChange={handleChange}
													state={state}
													currently_validating={currently_validating}
													myTab={3}
												/>
											</TabPanel>
											<TabPanel style={{ padding: 0 }} value="4">
												<ScheduleForm
													handleChange={handleChange}
													state={state}
													setChosenTime={setChosenTime}
													setWeek={setWeek}
													setClickedOnWeekIndex={setClickedOnWeekIndex}
													currently_validating={currently_validating}
													myTab={4}
												/>
											</TabPanel>
											{/* {state.company_invoice ?
                  <TabPanel style={{ padding: 0 }} value="5">
                    <PersonalInfo handleChange={handleChange} state={state} setCountry={setCountry} setCompanyInvoice={setCompanyInvoice} currently_validating={currently_validating} setCompanyInvoiceInfo={setCompanyInvoiceInfo} setICError={setICError} myTab={5} />
                  </TabPanel>
                  : null} */}
											<TabPanel style={{ padding: 0 }} value="6">
												<Summary
													state={state}
													handleChange={handleChange}
													setCouponActiveGeneric={setCouponActiveGeneric}
													wantCleaningPackage={wantCleaningPackage}
												/>
												<FormValidation state={state} />
											</TabPanel>
										</TabContext>

										{localStorage.getItem('availability') !== null && (
											<>
												{!actively_cleaning && first_time && tab == 1 ? (
													<Alert severity="warning">Bohužel vás nemůžeme pustit dál, protože ve vaší lokalitě neuklízíme.</Alert>
												) : null}
												{!actively_cleaning && !first_time && tab == 2 ? (
													<Alert
														severity="warning"
														style={{
															marginBottom: 40,
														}}
													>
														Bohužel vás nemůžeme pustit dál, protože ve vaší lokalitě neuklízíme.
													</Alert>
												) : null}
											</>
										)}

										<Grid container direction="row" justifyContent="space-between" alignItems="center" className="pb-16">
											{tab == 1 ? (
												<div />
											) : (
												<Grid item style={tab == 6 ? { width: '100%' } : {}}>
													<Button
														variant="outlined"
														onClick={prev}
														style={
															tab == 6
																? {
																		boxShadow: 'none',
																		width: '100%',
																		fontSize: 20,
																		fontFamily: 'agrandir',
																		textTransform: 'capitalize',
																		borderRadius: 100,
																		marginBottom: 20,
																  }
																: {
																		boxShadow: 'none',
																  }
														}
													>
														<span
															style={{
																position: 'relative',
																top: 2,
															}}
														>
															Předchozí
														</span>
													</Button>
												</Grid>
											)}
											{tab == 1 && (actively_cleaning || !first_time) ? (
												<Grid item>
													<Button
														variant="contained"
														color="secondary"
														onClick={next}
														style={{
															boxShadow: 'none',
														}}
													>
														Kalkulace
														<ArrowRight />
													</Button>
												</Grid>
											) : null}
											{tab != 1 && tab != 6 && actively_cleaning ? (
												<Grid item>
													<Button
														variant="contained"
														color="secondary"
														onClick={next}
														style={{
															boxShadow: 'none',
														}}
													>
														{state.tab == 1
															? 'Kalkulace'
															: state.tab == 2
															? 'Frekvence'
															: state.tab == 3
															? 'Termín'
															: state.tab == 4
															? 'Shrnutí'
															: state.tab == 5
															? 'Objednat'
															: ''}
														<ArrowRight />
													</Button>
												</Grid>
											) : null}

											{tab == 6 && (
												<Grid item className="w-full">
													<Finish state={state} />
												</Grid>
											)}
										</Grid>
									</Box>
								</Grid>
								<Default>
									{tab != 1 && tab != 4 && tab != 6 && (
										<Grid item flex={5}>
											<div style={{ height: 36 }} />
											<div
												style={{
													position: 'sticky',
													top: 36,
												}}
											>
												<PriceTest
													zone_id={JSON.parse(localStorage.getItem('availability'))?.zone_id}
													duration={duration}
													services={service_ids}
													coupon={state.coupon}
													user_id={localStorage.getItem('uid')}
													token={localStorage.getItem('token')}
													mode={'summary'}
												/>
											</div>
										</Grid>
									)}
								</Default>
							</Grid>
						</Grid>
					</div>
				</div>
				<div style={{ height: state.mobile ? 0 : 100 }} />
			</div>
		);
	}
}

export default App;
