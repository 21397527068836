import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import ContentWrapper from './Wrapper/ContentWrapper';
import { useNavigate, useParams } from 'react-router';
import { isMobile } from 'react-device-detect';

const OrderRating = () => {
	let { order_id } = useParams();
	const navigate = useNavigate();
	const profile = () => {
		navigate('/profil');
	};

	const [rating, setRating] = useState(null);
	const [stars, setStars] = useState(0);
	const [text, setText] = useState('');
	const [error, setError] = useState(null);
	const [mobile, setMobile] = useState({
		isMobile,
	});

	const ratingEmojis = ['/img/rating_1.svg', '/img/rating_2.svg', '/img/rating_3.svg', '/img/rating_4.svg'];

	const ratingMeanings = ['Katastrofa', 'Mohlo být lepší', 'Dobrý', 'Fantastický'];

	useEffect(() => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');

		//! reference point
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/orders/' + order_id + '/reviews?token=' + token, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setRating(data.review);
				} else {
					setRating(null);
				}
				console.log(data);
			});
	}, []);

	const sendRating = () => {
		setError(null);
		if (![1, 2, 3, 4].includes(stars)) {
			setError('Musíte zvolit počet hvězdiček.');
			return;
		}

		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');

		//! reference point
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/orders/' + order_id + '/reviews?token=' + token, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': '*',
				'Access-Control-Allow-Methods': '*',
			},
			body: JSON.stringify({
				review_rating: stars,
				review_text: text,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					window.location.reload(false);
				} else {
					setError('Něco se pokazilo.');
				}
			});
	};

	console.log(ratingEmojis);

	return (
		<ContentWrapper showCTA={false} maxWidth={'md'}>
			<Button
				style={{
					whiteSpace: 'nowrap',
					borderRadius: 19,
					boxShadow: 'none',
					background: '#004D58',
					textTransform: 'initial',
					fontFamily: 'agrandir',
				}}
				variant="contained"
				onClick={profile}
			>
				<span style={{ position: 'relative', top: 2 }}>Zpět</span>
			</Button>
			<div style={{ height: 40 }} />
			{error !== null && <Alert severity="error">{error}</Alert>}
			{rating !== null && <Alert severity="success">K této objednávce již hodnocení existuje.</Alert>}
			<div style={{ height: 40 }} />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Hodnocení úklidu
			</Typography>
			<Typography variant="body1">
				Najděte si chvilku a zanechte nám hodnocení.
				<div style={{ height: 5 }} />
				Snažíme se naše služby neustále zlepšovat, proto nám tato zpětná vazba velmi pomůže. Nebojte se nám také napsat konkrétně co můžeme do příště
				zlepšit.
			</Typography>
			<div style={{ height: 40 }} />
			{rating === null && (
				<>
					<Grid container justifyContent={'center'} direction={'row'}>
						{[4, 3, 2, 1].map((item) => (
							<Grid item direction={'column'} justifyContent={'center'} alignItems={'center'} style={{ margin: mobile ? '0 5px' : '10px 20px' }}>
								<Button
									onClick={() => setStars(item)}
									key={item}
									style={{
										background: stars === item ? '#f2f2f2' : 'initial',
									}}
								>
									<img src={ratingEmojis[item - 1]} alt="" style={{ width: mobile ? '40px' : 'initial' }} />
									{/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke={stars < item ? "#9e9e9e" : "#ffbf00"} fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path fill={stars < item ? "#9e9e9e" : "#ffbf00"} d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
								</svg> */}
								</Button>
								<Typography variant="body1" style={{ textAlign: 'center', fontSize: mobile ? '0.6em' : 'initial' }}>
									{ratingMeanings[item - 1]}
								</Typography>
							</Grid>
						))}
					</Grid>
					<div style={{ height: 40 }} />
					<TextField
						label="Co byste nám ještě chtěli sdělit? (nepovinné)"
						variant="standard"
						className="w-full"
						onChange={(e) => {
							setText(e.target.value);
						}}
						value={text}
						multiline
						InputLabelProps={{
							style: {
								color: 'rgba(0, 66, 80, 0.5)',
							},
						}}
					/>
					<div style={{ height: 40 }} />
					<Grid container justifyContent={'center'}>
						<Button
							style={{
								backgroundColor: '#8C5CBC',
								borderColor: '#8C5CBC',
								borderRadius: '400px',
								fontFamily: 'agrandir',
								textTransform: 'initial',
								fontSize: 20,
								padding: '10px 34px',
								boxShadow: 'none',
							}}
							variant="contained"
							onClick={sendRating}
						>
							<span style={{ position: 'relative', top: '2px' }}>Odeslat hodnocení</span>
						</Button>
					</Grid>
				</>
			)}
		</ContentWrapper>
	);
};

export default OrderRating;
