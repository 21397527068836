import { Button, Card, CardActions, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { Container } from 'postcss';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWrapper from './Wrapper/ContentWrapper';

function Paid(props) {
	const [state, setState] = useState({
		order: null,
		arb: 0,
		loaded: false,
	});
	let { uid, token } = useParams();
	const navigate = useNavigate();
	let dataOK = true;
	if (!uid) {
		if (localStorage.getItem('uid')) {
			uid = localStorage.getItem('uid');
		} else {
			// goto login
			dataOK = false;
		}
	}
	if (!token) {
		if (localStorage.getItem('token')) {
			token = localStorage.getItem('token');
		} else {
			// goto login
			dataOK = false;
		}
	}

	if (dataOK) {
		localStorage.setItem('uid', uid);
		localStorage.setItem('token', token);
		localStorage.setItem('first_time', 'no');
	}

	const { order_id } = useParams();

	useEffect(() => {
		if (!dataOK) {
			//navigate("/login");
		}
	}, []);

	useEffect(() => {
		// the order_id here is payment_id that is returning from comgate
		//! reference point
		const url = process.env.REACT_APP_API_URL + '/users/' + uid + '/payments/' + order_id + '/?token=' + token;
		const headers = {
			mode: 'cors',
			method: 'GET',
		};
		fetch(url, headers)
			.then((response) => response.json())
			.then((data) => {
				//console.log(data);
				setState({ ...data, arb: 1, loaded: true });
			});
	}, [state.arb]);

	//console.log(state.order);
	//return <div>{state.order}</div>;
	return (
		<ContentWrapper>
			<Grid container justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
				<Typography variant="h1" component="div">
					Děkujeme!
				</Typography>
				<img src="/img/checkmark.svg" alt="platba úspěšná" style={{ maxWidth: 150, margin: 20, height: 150 }} />
				{/* {state.credit == 'ok' &&
          <Typography style={{ margin: "20px 0", textAlign: "center" }} color="text.secondary" variant="body1">
            Prostředky jsme přidali do vaší peněženky.
          </Typography>
        } */}
				{state.order != null && (
					<ol style={{ listStyleType: 'decimal', listStylePosition: 'inside', maxWidth: 400 }}>
						<li style={{ marginTop: 20 }}>
							<Typography style={{ display: 'inline', textAlign: 'left' }} color="text.secondary" variant="body1">
								<strong>Vaše nejbližší objednávka Youklidu</strong> bude už{' '}
								{new Date(state.order.order_date).toLocaleDateString('cs-CZ').replace(/\s/g, '\u00A0')}.
							</Typography>
						</li>
						<li style={{ marginTop: 20 }}>
							<Typography style={{ display: 'inline', textAlign: 'left' }} color="text.secondary" variant="body1">
								<strong>Hospodyně dorazí na vaši adresu</strong> {state.order.apartment_address} v čase od{'\u00A0'}
								{state.order.order_begin.substring(11, 16).replace(/^0/, '') +
									'\u00A0do\u00A0' +
									state.order.order_end.substring(11, 16).replace(/^0/, '')}
								. Není potřeba nic dalšího zařizovat.
							</Typography>
						</li>
						<li style={{ marginTop: 20 }}>
							<Typography style={{ display: 'inline', textAlign: 'left' }} color="text.secondary" variant="body1">
								<strong>Před začátkem úklidu</strong> sdělte hospodyni své požadavky, ukažte jí úklidové vybavení a ovládání spotřebičů.
							</Typography>
						</li>
						<li style={{ marginTop: 20 }}>
							<Typography style={{ display: 'inline', textAlign: 'left' }} color="text.secondary" variant="body1">
								<strong>Pokud máte dotazy</strong>, kontaktujte nás na{' '}
								<a href={'mailto:info@youklid.cz'} style={{ textDecoration: 'underline' }}>
									info@youklid.cz
								</a>
							</Typography>
						</li>
						<li style={{ marginTop: 20 }}>
							<Typography style={{ display: 'inline', textAlign: 'left' }} color="text.secondary" variant="body1">
								<strong>Po úklidu nás ohodnoťte</strong> ve vašem profilu. Jsme rádi za každou zpětnou vazbu.
							</Typography>
						</li>
					</ol>
				)}
				{!state.loaded && (
					<>
						<Skeleton width={250} style={{ margin: '20px auto 0 auto' }} />
						<Skeleton width={250} style={{ margin: 'auto' }} />
					</>
				)}
				{dataOK && (
					<Button
						variant="contained"
						style={{
							backgroundColor: '#8C5CBC',
							borderColor: '#8C5CBC',
							borderRadius: '400px',
							fontFamily: 'agrandir',
							textTransform: 'initial',
							fontSize: 20,
							padding: '10px 34px',
							boxShadow: 'none',
							width: 215,
							height: 55,
							marginTop: 50,
						}}
						onClick={() => {
							navigate('/profil');
						}}
					>
						<span style={{ position: 'relative', top: 2 }}>Profil</span>
					</Button>
				)}
				{!dataOK && (
					<Button
						variant="contained"
						style={{
							backgroundColor: '#8C5CBC',
							borderColor: '#8C5CBC',
							borderRadius: '400px',
							fontFamily: 'agrandir',
							textTransform: 'initial',
							fontSize: 20,
							padding: '10px 34px',
							boxShadow: 'none',
							width: 215,
							height: 55,
							marginTop: 50,
						}}
						onClick={() => {
							navigate('/login');
						}}
					>
						<span style={{ position: 'relative', top: 2 }}>Přihlásit se</span>
					</Button>
				)}
				{/* {!state.loaded &&
          <Skeleton style={{ alignSelf: "end" }} width={90} height={60} />
        } */}
			</Grid>
		</ContentWrapper>
	);
}

export default Paid;
