import React from 'react';
import LayoutCard from './Components/LayoutCard';
import { Alert, Button, Chip, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useNavigate } from 'react-router';

function ProfileAd(props) {
	if (props?.ad_version == 2) return <Ad2 />;

	return <Ad1 />;
}

function Ad1() {
	const navigate = useNavigate();
	return (
		<LayoutCard>
			<Typography variant="h3" gutterBottom>
				Darujte Youklid
			</Typography>
			<Typography vairant="body1" style={{ marginBottom: 20 }}>
				Potěšte vaše blízké a kolegy poukázkou v libovolné hodnotě.
			</Typography>
			<Alert severity="success" style={{ marginBottom: 20 }}>
				<strong>Pozor!</strong> Nově nabízíme fakturaci na firmu a speciální podmínky při větším objemu.
			</Alert>
			<Grid container justifyContent={'center'}>
				<img style={{ width: '50%', minWidth: 300 }} src="/img/voucher_example_2.png" alt="Příklad poukázky" />
			</Grid>
			<Grid container justifyContent={'center'}>
				<Button
					style={{
						whiteSpace: 'nowrap',
						borderRadius: 19,
						boxShadow: 'none',
						color: '#004D58',
						outline: '#004D58',
						borderColor: '#004D58',
						textTransform: 'initial',
						fontFamily: 'agrandir',
						marginTop: 20,
					}}
					variant="outlined"
					onClick={() => {
						navigate('/poukazky');
					}}
				>
					<span style={{ position: 'relative', top: 2 }}>Zjistit více o poukázkách...</span>
				</Button>
			</Grid>
		</LayoutCard>
	);
}

function Ad2() {
	const navigate = useNavigate();

	const purpleChip = createTheme({
		palette: {
			purple: {
				main: '#8c5cbc',
				light: '#8c5cbc',
				dark: '#8c5cbc',
				contrastText: '#ffffff',
			},
		},
	});

	return (
		<LayoutCard>
			<Typography variant="h3" gutterBottom>
				Pozvěte přátele na Youklid
				<ThemeProvider theme={purpleChip}>
					<Chip label="Novinka" color="purple" size="small" style={{ marginLeft: 8 }} />
				</ThemeProvider>
			</Typography>
			<Typography vairant="body1" style={{ marginBottom: 20 }}>
				Doporučte nás vašim blízkým a potěšte je slevou na první objednávku.
			</Typography>
			<Grid container justifyContent={'center'}>
				<Button
					style={{
						whiteSpace: 'nowrap',
						borderRadius: 19,
						boxShadow: 'none',
						color: '#004D58',
						outline: '#004D58',
						borderColor: '#004D58',
						textTransform: 'initial',
						fontFamily: 'agrandir',
					}}
					variant="outlined"
					onClick={() => {
						navigate('/affiliate');
					}}
				>
					<span style={{ position: 'relative', top: 2 }}>Zajímá mě to</span>
				</Button>
			</Grid>
		</LayoutCard>
	);
}

export default ProfileAd;
