import React, { useState } from 'react';
import { Button, createTheme, Grid, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router';

const VouchersForm = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [amount, setAmount] = useState(1000);
	const [emailValid, setEmailValid] = useState(false);

	const validateEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

		// Check if the input matches the email pattern
		setEmailValid(emailRegex.test(email));

		return emailRegex.test(email);
	};

	const createPayment = () => {
		if (!validateEmail(email)) {
			return;
		}

		//! reference point
		fetch(process.env.REACT_APP_API_URL + '/voucher_payment', {
			method: 'post',
			body: JSON.stringify({
				email: email,
				name: name,
				amount: amount,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				if (data.status === 'ok') {
					window.location = data.url;
				} else {
					console.error('Error:', data.message);
				}
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	};

	return (
		<div style={{ margin: '32px 0' }}>
			<ThemeProvider
				theme={createTheme({
					palette: {
						primary: {
							main: '#8c5cbc',
						},
					},
					shape: {
						borderRadius: 100,
					},
				})}
			>
				<Grid container columnSpacing={6} justifyContent={'center'} alignItems={'center'}>
					<Grid item xs={12} md={6}>
						<TextField
							label="Email"
							type="email"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
								validateEmail(e.target.value);
							}}
							variant="outlined"
							margin="normal"
							fullWidth
							helperText={email.length > 0 ? (emailValid ? '' : 'Neplatný email') : ''}
							error={email.length > 0 && !emailValid}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Celé jméno"
							type="name"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
							variant="outlined"
							margin="normal"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Darovaná částka"
							type="number"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							variant="outlined"
							margin="normal"
							fullWidth
							InputProps={{
								inputProps: { min: 1000, max: 10000 },
								endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
							}}
						/>
					</Grid>
				</Grid>
				<div style={{ height: 10 }} />
				<Grid container justifyContent={'flex-end'}>
					<Button variant="contained" size="large" color="primary" onClick={createPayment}>
						Objednat poukázku
					</Button>
				</Grid>
			</ThemeProvider>
		</div>
	);
};

export default VouchersForm;
